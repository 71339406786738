@use "sass:math";

@import url('https://rsms.me/inter/inter.css');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

$ratio: 1.200;
$base-size: 1rem;
$h6s: $base-size;
$h5s: $h6s * $ratio;
$h4s: $h5s * $ratio;
$h3s: $h4s * $ratio;
$h2s: $h3s * $ratio;
$h1s: $h2s * $ratio;
$small-text: math.div($base-size, $ratio);

$accent-color: #BB2649;
$accent-color-lighter: #C7284D;

@mixin font-proportions($size) {
    font-size: $size;
    line-height: 1.4;
}

html { font-size: 18px; }

body {
    font-family: 'Inter', sans-serif;
    text-rendering: optimizeLegibility;
    @include font-proportions($base-size);

    hyphens: none;

    * {
	margin: 0;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Playfair Display', serif;
    margin: ($base-size * 2) 0;
}

h1 { @include font-proportions($h1s); }
h2 { @include font-proportions($h2s); }
h3 { @include font-proportions($h3s); }
h4 { @include font-proportions($h4s); }
h5 { @include font-proportions($h5s); }
h6 { @include font-proportions($h6s); }

main {
    max-width: 33rem;
    margin: auto
}

main * {
    margin: 1.4rem 0
}

main br {
    margin: 0
}

main nav {
    font-size: $small-text;
}

main nav ul {
    list-style: none;
    margin: 0
}

main #site-header {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center
}

main #site-header h2 {
    text-align: center
}

main #site-header nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between
}

main #site-header p {
    width: 80%;
    color: #939597;
    font-size: $small-text;
}

main article {
    margin: 3rem 0
}

main article header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between
}

main article header * {
    margin: 0.5rem 0;
}

main article header h1, h3 {
    text-align: center;
}

main article header span {
    color: #939597;
}

main article li {
    margin: 0;
}

main .post-preview header {
    flex-direction: row;
    align-items: baseline
}

main #site-footer {
    margin-top:4.5rem;
    margin-bottom:0;
    text-align:center
}

main #site-footer p {
    margin-bottom:0
}

.small-text {
    font-size: $small-text;
}

address {
    font-style:normal
}

pre {
    font-family:source-code-pro,monospace;
    //font-size:1.4rem
}

a, a:link, a:hover, a:visited { color: $accent-color; }

.footnotes {
    font-size: $small-text;
    overflow-wrap: break-word;
}

@media(max-width:425px) {
    html {
	font-size: 16px;
    }

    main {
	margin:0 0.75rem
    }
    main .post-preview header {
	flex-direction:column;
	align-items:baseline
    }
}

@media(max-width:375px) {
    .small-text {
	//font-size:1.06666667rem;
    };
    body {
	//font-size:1.6rem;
    };
    h3 {
	//font-size:3.2rem;
    };
    h2 {
	//font-size:4.8rem;
    };
    h1 {
	//font-size:6.4rem;
    };
    main {
	margin:0 0.6rem
    };
    main * {
	margin:1.3rem 0
    };
    main article {
	margin:2.6rem 0
    };
    main #site-footer {
	margin-top:4rem
    }
}

@media(prefers-color-scheme: light) {
    body {
	background-color: white;
	color: #111;
    }

    a, a:link, a:hover, a:visited { color: $accent-color; }
}

@media(prefers-color-scheme: dark) {
    body {
	background-color: #111;
	color: white;
    }

    a, a:link, a:hover, a:visited { color: $accent-color-lighter; }
}

@supports (font-variation-settings: normal) {
    body { font-family: 'Inter var', sans-serif; }
}
